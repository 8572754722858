defineDs('DanskeSpil/Domain/Feature.Components/Scripts/Accordion.js', [], function () {

  if (!document.querySelector('.js-accordion-item')) {
    return;
  }

  const accordionItems = document.querySelectorAll('.js-accordion-item');

  accordionItems?.forEach((item) => {
    const toggle = item.querySelector('.js-accordion-title');
    const content = item.querySelector('.js-accordion-content');
    const wrapper = toggle.closest('.js-accordion-wrapper');
    toggle?.addEventListener('click', () => {
      acordionItemToggle(wrapper, toggle, content);
    });

    item?.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        acordionItemToggle(wrapper, toggle, content);
      }
    });
  });


  const acordionItemToggle = (wrapper, toggle, content) => {
    if (toggle.ariaExpanded === 'false') {
      wrapper.querySelectorAll('[aria-hidden="false"]').forEach((e) => e.setAttribute('aria-hidden', 'true'));
      wrapper.querySelectorAll('[aria-expanded="true"]').forEach((e) => e.setAttribute('aria-expanded', 'false'));
    }
    toggle.setAttribute('aria-expanded', toggle.ariaExpanded === 'true' ? 'false' : 'true');
    content.setAttribute('aria-hidden', content.ariaHidden === 'true' ? 'false' : 'true');
  };
});
